import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeBE from '@angular/common/locales/nl-BE';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { PopupComponent } from '@components/popup/popup.component';
import { DirectivesModule } from '@directives/directives.module';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppointmentsProvider } from '@providers/appointments.provider';
import { CalendarProvider } from '@providers/calendar.provider';
import { CalendarEventReservationsProvider } from '@providers/calendarEventReservations.provider';
import { ClientProvider } from '@providers/client.provider';
import { FilterSessionsProvider } from '@providers/filtersessions.provider';
import { GoogleProvider } from '@providers/google.provider';
import { LoginProvider } from '@providers/login.provider';
import { PaymentsProvider } from '@providers/payments.provider';
import { TokenInterceptor } from '@providers/token.interceptor';

import { AppBaseComponent } from './app-base.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeBE);

@NgModule({
  declarations: [
    AppComponent,
    AppBaseComponent,
    NavigationComponent,
    PopupComponent,
  ],
  entryComponents: [PopupComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    DirectivesModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    LoginProvider,
    ClientProvider,
    AppointmentsProvider,
    FilterSessionsProvider,
    GoogleProvider,
    CalendarProvider,
    PaymentsProvider,
    CalendarEventReservationsProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
