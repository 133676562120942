/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "./components/navigation/navigation.component.ngfactory";
import * as i4 from "./components/navigation/navigation.component";
import * as i5 from "./services/authentication.service";
import * as i6 from "./providers/client.provider";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./app-base.component";
var styles_AppBaseComponent = ["app-navigation#navigation[_ngcontent-%COMP%] {\n        z-index: 9;\n        width: 300px;\n        border-right: none;\n      }\n      #sub-content[_ngcontent-%COMP%] {\n        background: var(--ion-color-light);\n      }"];
var RenderType_AppBaseComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AppBaseComponent, data: {} });
export { RenderType_AppBaseComponent as RenderType_AppBaseComponent };
export function View_AppBaseComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "ion-split-pane", [["contentId", "sub-content"]], null, null, null, i1.View_IonSplitPane_0, i1.RenderType_IonSplitPane)), i0.ɵdid(1, 49152, null, 0, i2.IonSplitPane, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { contentId: [0, "contentId"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "app-navigation", [["id", "navigation"]], null, null, null, i3.View_NavigationComponent_0, i3.RenderType_NavigationComponent)), i0.ɵdid(3, 114688, null, 0, i4.NavigationComponent, [i5.AuthenticationService, i6.ClientProvider], null, null), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "div", [["id", "sub-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "ion-router-outlet", [], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i2.IonRouterOutlet, [i7.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], [8, null], i2.Config, i2.NavController, i8.Location, i0.ElementRef, i7.Router, i0.NgZone, i7.ActivatedRoute, [3, i2.IonRouterOutlet]], null, null)], function (_ck, _v) { var currVal_0 = "sub-content"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); _ck(_v, 6, 0); }, null); }
export function View_AppBaseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-base", [], null, null, null, View_AppBaseComponent_0, RenderType_AppBaseComponent)), i0.ɵdid(1, 114688, null, 0, i9.AppBaseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppBaseComponentNgFactory = i0.ɵccf("app-base", i9.AppBaseComponent, View_AppBaseComponent_Host_0, {}, {}, []);
export { AppBaseComponentNgFactory as AppBaseComponentNgFactory };
