import { Injectable } from '@angular/core';
import { IClient, IPartner } from '@models/client.model';
import { APIProvider } from '@providers/api.provider';

@Injectable()
export class ClientProvider {
  constructor(private api: APIProvider) {}

  getClient(): Promise<IClient> {
    return this.api.get(`/mybeego/client`);
  }

  getPartner(): Promise<IPartner> {
    return this.api.get(`/mybeego/client/partner`);
  }

  updateClient(data: Partial<IClient>): Promise<IClient> {
    return this.api.post(`/mybeego/client/update`, data);
  }
}
