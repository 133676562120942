import * as i0 from "@angular/core";
export class StorageService {
    constructor() { }
    getItem(key) {
        return new Promise((resolve, reject) => {
            const item = JSON.parse(localStorage.getItem(key));
            if (item === null) {
                reject(`Failed to find ${key}`);
            }
            else {
                resolve(item);
            }
        });
    }
    getItemSync(key) {
        return JSON.parse(localStorage.getItem(key));
    }
    setItem(key, data) {
        return new Promise((resolve) => {
            localStorage.setItem(key, JSON.stringify(data));
            resolve();
        });
    }
    removeItem(key) {
        return new Promise((resolve) => {
            localStorage.removeItem(key);
            resolve();
        });
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
