import { Routes } from '@angular/router';
import { LoginGuard } from '@guards/login.guard';
import { AppBaseComponent } from './app-base.component';
const ɵ0 = () => import("./pages/login/login.module.ngfactory").then((m) => m.LoginPageModuleNgFactory), ɵ1 = () => import("./pages/register/register.module.ngfactory").then((m) => m.RegisterPageModuleNgFactory), ɵ2 = () => import("./pages/reset/reset.module.ngfactory").then((m) => m.ResetPageModuleNgFactory), ɵ3 = () => import("./pages/verify/verify.module.ngfactory").then((m) => m.VerifyPageModuleNgFactory), ɵ4 = () => import("./pages/dashboard/dashboard.module.ngfactory").then((m) => m.DashboardPageModuleNgFactory), ɵ5 = () => import("./pages/appointments/appointments.module.ngfactory").then((m) => m.AppointmentsPageModuleNgFactory), ɵ6 = () => import("./pages/appointment-create/appointment-create.module.ngfactory").then((m) => m.AppointmentCreatePageModuleNgFactory), ɵ7 = () => import("./pages/appointment-create-partner/appointment-create-partner.module.ngfactory").then((m) => m.AppointmentCreatePartnerPageModuleNgFactory), ɵ8 = () => import("./pages/appointment-overview/appointment-overview.module.ngfactory").then((m) => m.AppointmentOverviewPageModuleNgFactory), ɵ9 = () => import("./pages/membership/membership.module.ngfactory").then((m) => m.MembershipPageModuleNgFactory), ɵ10 = () => import("./pages/profile/profile.module.ngfactory").then((m) => m.ProfilePageModuleNgFactory), ɵ11 = () => import("./pages/dashboard/dashboard.module.ngfactory").then((m) => m.DashboardPageModuleNgFactory), ɵ12 = { notfound: true }, ɵ13 = () => import("./pages/appointment-create-partner/appointment-create-partner.module.ngfactory").then((m) => m.AppointmentCreatePartnerPageModuleNgFactory);
const routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: ɵ0,
    },
    {
        path: 'register',
        loadChildren: ɵ1,
    },
    {
        path: 'reset',
        loadChildren: ɵ2,
    },
    {
        path: 'verify',
        loadChildren: ɵ3,
    },
    {
        path: '',
        component: AppBaseComponent,
        canActivate: [LoginGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: ɵ4,
            },
            {
                path: 'afspraken',
                loadChildren: ɵ5,
            },
            {
                path: 'nieuw',
                loadChildren: ɵ6,
            },
            {
                path: 'nieuw/partner',
                loadChildren: ɵ7,
            },
            {
                path: 'afspraken/:id',
                loadChildren: ɵ8,
            },
            {
                path: 'lidmaatschap',
                loadChildren: ɵ9,
            },
            {
                path: 'profiel',
                loadChildren: ɵ10,
            },
            {
                path: '**',
                loadChildren: ɵ11,
                data: ɵ12,
            },
        ],
    },
    {
        path: 'appointment-create-partner',
        loadChildren: ɵ13,
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
