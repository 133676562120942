import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IAPIObject, IListData } from '@libs/interfaces';

enum HTTPMETHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  SEARCH = 'SEARCH',
}

@Injectable({
  providedIn: 'root',
})
export class APIProvider {
  constructor(private readonly http: HttpClient) {}

  private request<Model>(
    method: HTTPMETHOD,
    url: string,
    body?: any
  ): Promise<Model> {
    return new Promise((resolve, reject) => {
      this.http.request(method, environment.api + url, { body }).subscribe(
        (result) => {
          resolve((result as IAPIObject<Model>).data as Model);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public get<Model>(url: string): Promise<Model> {
    return this.request<Model>(HTTPMETHOD.GET, url);
  }
  public search<Model>(url: string, body: Partial<Model>): Promise<Model> {
    return this.request(HTTPMETHOD.SEARCH, url, body);
  }
  public post<Model>(url: string, body: any): Promise<Model> {
    return this.request(HTTPMETHOD.POST, url, body);
  }
  public put<Model>(url: string, body: Partial<Model>): Promise<Model> {
    return this.request(HTTPMETHOD.PUT, url, body);
  }
  public delete(url: string): Promise<void> {
    return this.request(HTTPMETHOD.DELETE, url);
  }
}
