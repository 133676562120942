import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@guards/login.guard';

import { AppBaseComponent } from './app-base.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'reset',
    loadChildren: () =>
      import('./pages/reset/reset.module').then((m) => m.ResetPageModule),
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('./pages/verify/verify.module').then((m) => m.VerifyPageModule),
  },
  {
    path: '',
    component: AppBaseComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardPageModule
          ),
      },
      {
        path: 'afspraken',
        loadChildren: () =>
          import('./pages/appointments/appointments.module').then(
            (m) => m.AppointmentsPageModule
          ),
      },
      {
        path: 'nieuw',
        loadChildren: () =>
          import('./pages/appointment-create/appointment-create.module').then(
            (m) => m.AppointmentCreatePageModule
          ),
      },
      {
        path: 'nieuw/partner',
        loadChildren: () =>
          import(
            './pages/appointment-create-partner/appointment-create-partner.module'
          ).then((m) => m.AppointmentCreatePartnerPageModule),
      },
      {
        path: 'afspraken/:id',
        loadChildren: () =>
          import(
            './pages/appointment-overview/appointment-overview.module'
          ).then((m) => m.AppointmentOverviewPageModule),
      },
      {
        path: 'lidmaatschap',
        loadChildren: () =>
          import('./pages/membership/membership.module').then(
            (m) => m.MembershipPageModule
          ),
      },
      {
        path: 'profiel',
        loadChildren: () =>
          import('./pages/profile/profile.module').then(
            (m) => m.ProfilePageModule
          ),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardPageModule
          ),
        data: { notfound: true },
      },
    ],
  },
  {
    path: 'appointment-create-partner',
    loadChildren: () =>
      import(
        './pages/appointment-create-partner/appointment-create-partner.module'
      ).then((m) => m.AppointmentCreatePartnerPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
