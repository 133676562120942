<div class="main text-center">
  <div class="status">
    <div class="circle success" *ngIf="status === 0">
      <ion-icon name="checkmark"></ion-icon>
    </div>
    <div class="circle danger" *ngIf="status === 1">
      <ion-icon name="close-outline"></ion-icon>
    </div>
    <div class="circle warning" *ngIf="status === 2">
      <ion-icon name="alert-outline"></ion-icon>
    </div>
    <div class="circle info" *ngIf="status === 3">
      <ion-icon name="information-outline"></ion-icon>
    </div>
  </div>
  <h1>
    {{ title }}
  </h1>
  <p>
    {{ body }}
  </p>
  <div class="buttons">
    <button class="btn btn-secondary" (click)="close()">
      Sluit
    </button>
  </div>
</div>
