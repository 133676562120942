export enum StorageKey {
  tokenSet = 'TokenSet',
  user = 'User',
}

export enum EClientAppointmentStep {
  filter = 0,
  calendar = 1,
  confirm = 2,
}

export enum EPartnerAppointmentStep {
  zipcode = 0,
  filter = 1,
  calendar = 2,
  client = 3,
  confirm = 4,
}

export enum EPopupStatus {
  success,
  danger,
  warning,
  info,
}
