import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ERole } from '@models/user.model';
import { ClientProvider } from '@providers/client.provider';
import { AuthenticationService } from '@services/authentication.service';
export class NavigationComponent {
    constructor(authenticationService, clientProvider) {
        this.authenticationService = authenticationService;
        this.clientProvider = clientProvider;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.authenticationService.getRole() === ERole.Partner) {
                this.partner = yield this.clientProvider.getPartner().catch();
            }
        });
    }
    logout() {
        this.authenticationService.logout();
    }
}
