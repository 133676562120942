import { Injectable } from '@angular/core';
import { APIProvider } from '@providers/api.provider';
import { ICalendarEventReservation } from '@models/calendarEventReservation.model';

@Injectable()
export class CalendarEventReservationsProvider {
  constructor(private api: APIProvider) {}

  createReservation(
    calendarEventId: number
  ): Promise<ICalendarEventReservation> {
    return this.api.post('/calendarEventReservations', { calendarEventId });
  }

  removeReservation(calendarEventId: number): Promise<void> {
    return this.api.delete(`/calendarEventReservations/${calendarEventId}`);
  }
}
