<div class="menu" contentId="main-content">
  <ion-content>
    <div class="logo">
      <img src="assets/imgs/logo.png" />
    </div>

    <div class="partner-logo" *ngIf="partner">
      <img [src]="partner.logo" />
    </div>

    <ion-list class="main">
      <ion-menu-toggle auto-hide="false">
        <ion-item
          routerDirection=" root"
          [routerLink]="'/dashboard'"
          routerLinkActive="selected"
          lines="none"
          detail="false"
        >
          <ion-icon slot="start" icon="home-sharp"></ion-icon>
          <ion-label>Overzicht</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="false" *appRole="'Client'">
        <ion-item
          routerDirection="root"
          [routerLink]="'/nieuw'"
          routerLinkActive="selected"
          lines="none"
          detail="false"
        >
          <ion-icon slot="start" icon="calendar-sharp"></ion-icon>
          <ion-label>Inplannen</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="false" *appRole="'Partner'">
        <ion-item
          routerDirection="root"
          [routerLink]="'/nieuw/partner'"
          routerLinkActive="selected"
          lines="none"
          detail="false"
        >
          <ion-icon slot="start" icon="calendar-sharp"></ion-icon>
          <ion-label>Inplannen</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="false">
        <ion-item
          routerDirection="root"
          [routerLink]="'/afspraken'"
          routerLinkActive="selected"
          lines="none"
          detail="false"
        >
          <ion-icon slot="start" icon="checkbox-sharp"></ion-icon>
          <ion-label>Afspraken</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="false" *appRole="'Client'">
        <ion-item
          routerDirection="root"
          [routerLink]="'/lidmaatschap'"
          routerLinkActive="selected"
          lines="none"
          detail="false"
        >
          <ion-icon slot="start" icon="star-sharp"></ion-icon>
          <ion-label>Lidmaatschap</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="false" *appRole="'Client'">
        <ion-item
          routerDirection="root"
          [routerLink]="'/profiel'"
          routerLinkActive="selected"
          lines="none"
          detail="false"
        >
          <ion-icon slot="start" icon="person-sharp"></ion-icon>
          <ion-label>Gegevens</ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="false">
        <ion-item
          (click)="logout()"
          lines="none"
          detail="false"
          [routerLink]="'/'"
        >
          <ion-icon slot="start" icon="log-out-sharp"></ion-icon>
          <ion-label>Logout</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>

    <!-- <ion-list style="position: absolute; left: 0; bottom: 32px; width: 100%; display: none;">
      <ion-item style="border-bottom: none !important; cursor: pointer;">
        <ion-icon [icon]="'log-out-sharp'"></ion-icon>
        <ion-label>Logout</ion-label>
      </ion-item>
    </ion-list> -->
  </ion-content>
</div>
