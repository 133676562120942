import { Injectable } from '@angular/core';
import { IAppointment } from '@models/appointment.model';
import { APIProvider } from '@providers/api.provider';

@Injectable()
export class AppointmentsProvider {
  constructor(private api: APIProvider) {}

  getAll(): Promise<IAppointment[]> {
    return this.api.get('/mybeego/appointments');
  }

  getOne(id: number): Promise<IAppointment> {
    return this.api.get(`/mybeego/appointments/${id}`);
  }

  createAppointment(
    event,
    appointment
  ): Promise<{ appointment: IAppointment; checkout: string }> {
    return this.api.post('/mybeego/appointments/create', {
      event,
      notes: appointment.notes,
      problem: appointment.problem,
    });
  }

  createPartnerAppointment(
    event,
    data: { problem: string; code: string },
    client
  ): Promise<{ appointment: IAppointment; checkout: string }> {
    return this.api.post('/mybeego/appointments/create/partner', {
      event,
      problem: data.problem,
      code: data.code,
      client,
    });
  }
}
