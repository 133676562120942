import { Injectable } from '@angular/core';
import { IPayment } from '@models/payments.model';
import { APIProvider } from '@providers/api.provider';

@Injectable()
export class PaymentsProvider {
  constructor(private api: APIProvider) {}

  getPaymentByWorkorderId(workorderId: number): Promise<IPayment> {
    return this.api.get(`/mybeego/payments/${workorderId}`);
  }
}
