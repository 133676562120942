export interface IUser {
  firstname: string;
  name: string;
  fullname: string;
  avatar: string;
  role: ERole;
}

export enum ERole {
  Client = 'Client',
  Partner = 'Partner',
}

export interface IUserTokenSet {
  token: string;
  refreshToken: string;
  device: string;
  role: ERole;
}
