import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  template: `
    <ion-split-pane contentId="sub-content">
      <app-navigation id="navigation"></app-navigation>
      <div id="sub-content">
        <ion-router-outlet></ion-router-outlet>
      </div>
    </ion-split-pane>
  `,
  styles: [
    `
      app-navigation#navigation {
        z-index: 9;
        width: 300px;
        border-right: none;
      }
      #sub-content {
        background: var(--ion-color-light);
      }
    `,
  ],
})
export class AppBaseComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
