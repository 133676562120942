import { Injectable } from '@angular/core';
import {
  IFilterSession,
  IQuestion,
  ITalentTag,
} from '@models/filtersession.model';
import { APIProvider } from '@providers/api.provider';

@Injectable()
export class FilterSessionsProvider {
  constructor(private api: APIProvider) {}

  getFilterSession(): Promise<IFilterSession> {
    return this.api.get('/mybeego/filtersession');
  }

  getTalentTags(): Promise<ITalentTag[]> {
    return this.api.get(`/mybeego/filtersession/talentTags`);
  }

  nextQuestion(data): Promise<IQuestion> {
    return this.api.post('/mybeego/filtersession/nextQuestion', data);
  }

  resetSession(): Promise<IQuestion> {
    return this.api.post('/mybeego/filtersession/reset', {});
  }

  overrideSession(talentTags: ITalentTag[]): Promise<ITalentTag[]> {
    return this.api.post('/mybeego/filtersession/override', talentTags);
  }
}
