import { Injectable } from '@angular/core';
import { StorageKey } from '@libs/enums';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public getItem<Model>(key: StorageKey): Promise<Model> {
    return new Promise((resolve, reject) => {
      const item = JSON.parse(localStorage.getItem(key));
      if (item === null) {
        reject(`Failed to find ${key}`);
      } else {
        resolve(item);
      }
    });
  }

  public getItemSync<Model>(key: StorageKey): Model {
    return JSON.parse(localStorage.getItem(key));
  }

  public setItem(key: StorageKey, data: any): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem(key, JSON.stringify(data));
      resolve();
    });
  }

  public removeItem(key: StorageKey): Promise<void> {
    return new Promise((resolve) => {
      localStorage.removeItem(key);
      resolve();
    });
  }
}
