import { Component, OnInit, Input } from '@angular/core';
import { EPopupStatus } from '@libs/enums';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  @Input() status: EPopupStatus = EPopupStatus.success;
  @Input() title: string = '';
  @Input() body: string = '';

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
