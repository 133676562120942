import { of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { StorageKey } from '@libs/enums';
import { LoginProvider } from '@providers/login.provider';
import { StorageService } from './storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./storage.service";
import * as i2 from "../providers/login.provider";
import * as i3 from "@ionic/angular";
export class AuthenticationService {
    constructor(storageService, loginProvider, navController) {
        this.storageService = storageService;
        this.loginProvider = loginProvider;
        this.navController = navController;
        this.setTokenSet(this.storageService.getItemSync(StorageKey.tokenSet));
        this.setUser(this.storageService.getItemSync(StorageKey.user));
    }
    isAuthenticated() {
        if (this.user) {
            this.authenticated = true;
        }
        else {
            const tokenSet = this.storageService.getItemSync(StorageKey.tokenSet);
            if (tokenSet && tokenSet.token) {
                this.setTokenSet(tokenSet);
                this.authenticated = true;
            }
            else {
                this.authenticated = false;
            }
        }
        return this.authenticated;
    }
    login(credentials) {
        return new Promise((resolve, reject) => {
            this.loginProvider
                .login(credentials)
                .then((result) => {
                this.setTokenSet(result);
                // TODO Get User
                this.authenticated = true;
                this.navController
                    .navigateForward('dashboard', { animated: false })
                    .catch();
                resolve();
            })
                .catch(() => reject());
        });
    }
    loginSocial(credentials) {
        return new Promise((resolve, reject) => {
            this.loginProvider
                .loginSocial(credentials)
                .then((result) => {
                this.setTokenSet(result);
                this.authenticated = true;
                this.navController
                    .navigateForward('dashboard', { animated: false })
                    .catch();
                resolve();
            })
                .catch(() => reject());
        });
    }
    logout() {
        this.loginProvider.logout().then(() => {
            this.removeTokenSet();
            // this.navController.navigateBack('login', { animated: false }).catch();
            window.location = ('http://' + window.location.host);
        });
    }
    refresh() {
        return this.loginProvider
            .refresh(this.getRefreshToken(), this.getDevice())
            .pipe(tap((tokenSet) => this.setTokenSet(tokenSet)), mapTo(true), catchError((error) => {
            this.removeTokenSet();
            this.navController.navigateBack('login', { animated: false }).catch();
            return of(null);
        }));
    }
    getToken() {
        return this.tokenSet ? this.tokenSet.token : null;
    }
    getRefreshToken() {
        return this.tokenSet ? this.tokenSet.refreshToken : null;
    }
    getRole() {
        return this.tokenSet ? this.tokenSet.role : null;
    }
    getDevice() {
        return this.tokenSet ? this.tokenSet.device : null;
    }
    getUser() {
        return this.user;
    }
    setTokenSet(tokenSet) {
        this.storageService.setItem(StorageKey.tokenSet, tokenSet);
        this.tokenSet = tokenSet;
    }
    setUser(user) {
        this.storageService.setItem(StorageKey.user, user);
        this.user = user;
    }
    removeTokenSet() {
        this.storageService.removeItem(StorageKey.tokenSet);
        this.storageService.removeItem(StorageKey.user);
        this.user = null;
        this.tokenSet = null;
        this.authenticated = false;
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.StorageService), i0.ɵɵinject(i2.LoginProvider), i0.ɵɵinject(i3.NavController)); }, token: AuthenticationService, providedIn: "root" });
