import { SocialUser } from 'angularx-social-login';
import { from, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { IUserTokenSet } from '@models/user.model';
import { APIProvider } from '@providers/api.provider';

@Injectable()
export class LoginProvider {
  constructor(private api: APIProvider) {}

  checkMail(email): Promise<any> {
    return this.api.post('/mybeego/register/check', { email });
  }

  checkPhone(phone): Promise<any> {
    return this.api.post('/mybeego/register/check', { phone });
  }

  register(data) {
    return this.api.post('/mybeego/register', data);
  }

  login(data): Promise<IUserTokenSet> {
    return this.api.post<IUserTokenSet>('/mybeego/authentication/login', data);
  }

  loginSocial(data: SocialUser): Promise<IUserTokenSet> {
    return this.api.post<IUserTokenSet>(
      '/mybeego/authentication/login/social',
      data
    );
  }

  refresh(refreshToken: string, device: string): Observable<IUserTokenSet> {
    return from(
      this.api.post<IUserTokenSet>('/mybeego/authentication/refresh', {
        refreshToken,
        device,
      })
    );
  }

  verify(code): Promise<any> {
    return this.api.post('/mybeego/register/verify', { code });
  }

  resetPassword(data): Promise<any> {
    return this.api.post('/mybeego/client/password/reset', data);
  }

  changePassword(data): Promise<any> {
    return this.api.post('/mybeego/client/password/change', data);
  }

  checkPasswordReset(data): Promise<any> {
    return this.api.post('/mybeego/client/password/check', data);
  }

  logout(): Promise<any> {
    return this.api.post('/mybeego/token/destroy', {});
  }
}
