import { Injectable } from '@angular/core';
import { IFilterQuery, IZipcode } from '@libs/interfaces';
import { APIProvider } from '@providers/api.provider';

@Injectable()
export class CalendarProvider {
  constructor(private api: APIProvider) {}

  getCalendarEvents(query: IFilterQuery): Promise<any> {
    return this.api.post(`/mybeego/calendar/events`, { query });
  }

  checkZipcodeActive(zipcode: string | number): Promise<IZipcode> {
    return this.api.get(`/mybeego/calendar/zipcodes/${zipcode}`);
  }
}
