import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { ERole } from '@models/user.model';

@Directive({
  selector: '[appRole]',
})
export class RoleDirective {
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly authenticationService: AuthenticationService
  ) {}

  @Input() set appRole(roles: ERole | ERole[]) {
    if (
      roles === this.authenticationService.getRole() ||
      roles.indexOf(this.authenticationService.getRole()) !== -1
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
