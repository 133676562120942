import { Component, OnInit } from '@angular/core';
import { IPartner } from '@models/client.model';
import { ERole } from '@models/user.model';
import { ClientProvider } from '@providers/client.provider';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public partner!: IPartner;

  constructor(
    private authenticationService: AuthenticationService,
    private clientProvider: ClientProvider
  ) {}

  async ngOnInit() {
    if (this.authenticationService.getRole() === ERole.Partner) {
      this.partner = await this.clientProvider.getPartner().catch();
    }
  }

  logout() {
    this.authenticationService.logout();
  }
}
