import { SocialUser } from 'angularx-social-login';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { StorageKey } from '@libs/enums';
import { ILoginCredentials } from '@libs/interfaces';
import { ERole, IUser, IUserTokenSet } from '@models/user.model';
import { LoginProvider } from '@providers/login.provider';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private user: IUser;
  private tokenSet: IUserTokenSet;
  private authenticated: boolean;

  constructor(
    private storageService: StorageService,
    private loginProvider: LoginProvider,
    private navController: NavController
  ) {
    this.setTokenSet(
      this.storageService.getItemSync<IUserTokenSet>(StorageKey.tokenSet)
    );
    this.setUser(this.storageService.getItemSync<IUser>(StorageKey.user));
  }

  public isAuthenticated(): boolean {
    if (this.user) {
      this.authenticated = true;
    } else {
      const tokenSet = this.storageService.getItemSync<IUserTokenSet>(
        StorageKey.tokenSet
      );
      if (tokenSet && tokenSet.token) {
        this.setTokenSet(tokenSet);
        this.authenticated = true;
      } else {
        this.authenticated = false;
      }
    }
    return this.authenticated;
  }

  public login(credentials: ILoginCredentials): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loginProvider
        .login(credentials)
        .then((result) => {
          this.setTokenSet(result);
          // TODO Get User
          this.authenticated = true;
          this.navController
            .navigateForward('dashboard', { animated: false })
            .catch();
          resolve();
        })
        .catch(() => reject());
    });
  }

  public loginSocial(credentials: SocialUser): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loginProvider
        .loginSocial(credentials)
        .then((result) => {
          this.setTokenSet(result);
          this.authenticated = true;
          this.navController
            .navigateForward('dashboard', { animated: false })
            .catch();
          resolve();
        })
        .catch(() => reject());
    });
  }

  public logout(): void {
    this.loginProvider.logout().then(() => {
      this.removeTokenSet();
      // this.navController.navigateBack('login', { animated: false }).catch();
      window.location = ('http://' + window.location.host) as any;
    });
  }

  public refresh(): Observable<IUserTokenSet> {
    return this.loginProvider
      .refresh(this.getRefreshToken(), this.getDevice())
      .pipe(
        tap((tokenSet) => this.setTokenSet(tokenSet)),
        mapTo(true),
        catchError((error) => {
          this.removeTokenSet();
          this.navController.navigateBack('login', { animated: false }).catch();
          return of(null);
        })
      );
  }

  public getToken(): string {
    return this.tokenSet ? this.tokenSet.token : null;
  }

  public getRefreshToken(): string {
    return this.tokenSet ? this.tokenSet.refreshToken : null;
  }

  public getRole(): ERole {
    return this.tokenSet ? this.tokenSet.role : null;
  }

  public getDevice(): string {
    return this.tokenSet ? this.tokenSet.device : null;
  }

  public getUser(): IUser {
    return this.user;
  }

  public setTokenSet(tokenSet: IUserTokenSet): void {
    this.storageService.setItem(StorageKey.tokenSet, tokenSet);
    this.tokenSet = tokenSet;
  }

  private setUser(user: IUser): void {
    this.storageService.setItem(StorageKey.user, user);
    this.user = user;
  }

  private removeTokenSet() {
    this.storageService.removeItem(StorageKey.tokenSet);
    this.storageService.removeItem(StorageKey.user);
    this.user = null;
    this.tokenSet = null;
    this.authenticated = false;
  }
}
