import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { IAPIObject } from '@libs/interfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HTTPMETHOD;
(function (HTTPMETHOD) {
    HTTPMETHOD["GET"] = "GET";
    HTTPMETHOD["POST"] = "POST";
    HTTPMETHOD["PUT"] = "PUT";
    HTTPMETHOD["DELETE"] = "DELETE";
    HTTPMETHOD["SEARCH"] = "SEARCH";
})(HTTPMETHOD || (HTTPMETHOD = {}));
export class APIProvider {
    constructor(http) {
        this.http = http;
    }
    request(method, url, body) {
        return new Promise((resolve, reject) => {
            this.http.request(method, environment.api + url, { body }).subscribe((result) => {
                resolve(result.data);
            }, (error) => {
                reject(error);
            });
        });
    }
    get(url) {
        return this.request(HTTPMETHOD.GET, url);
    }
    search(url, body) {
        return this.request(HTTPMETHOD.SEARCH, url, body);
    }
    post(url, body) {
        return this.request(HTTPMETHOD.POST, url, body);
    }
    put(url, body) {
        return this.request(HTTPMETHOD.PUT, url, body);
    }
    delete(url) {
        return this.request(HTTPMETHOD.DELETE, url);
    }
}
APIProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function APIProvider_Factory() { return new APIProvider(i0.ɵɵinject(i1.HttpClient)); }, token: APIProvider, providedIn: "root" });
