import { NavController } from '@ionic/angular';
import { AuthenticationService } from '@services/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@ionic/angular";
export class LoginGuard {
    constructor(authenticationService, navController) {
        this.authenticationService = authenticationService;
        this.navController = navController;
    }
    canActivate() {
        if (this.authenticationService.isAuthenticated()) {
            return true;
        }
        this.navController.navigateBack('login', { animated: true }).catch();
        return false;
    }
}
LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.NavController)); }, token: LoginGuard, providedIn: "root" });
