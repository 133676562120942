import { Injectable } from '@angular/core';
import { APIProvider } from '@providers/api.provider';

@Injectable()
export class GoogleProvider {
  constructor(private api: APIProvider) {}

  addressAutocomplete(val): Promise<any> {
    return this.api.search(`/google`, { address: val });
  }

  fullAddressInfo(placeId): Promise<any> {
    return this.api.get(`/google?id=${placeId}`);
  }
}
